#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 0, 0, 0.3);
  }
  
  
  .user-avatar {

     justify-content: left;
  }

  .search-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    z-index: 999; /* Z-index alto para mantenerlo por encima del contenido */
  }
  
  input, button {
    padding: 5px;
    margin: 5px;
  }

body, input, textarea, select{
  font-family: 'Red Hat Display',sans-serif;
  font-weight: 400; /* 400,700,900 */
  font-style: normal;
  font-display: swap;
  color: #051D49;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Red Hat Display',sans-serif;
    font-weight: 700; /* 400,700,900 */
    font-style: normal;
    font-display: swap;
}

  p, li {
    font-size: 1.0em;
    font-family: 'Red Hat Display',sans-serif;
  }

  .vacantes {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fadeInEffect {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.fadeInEffect.show {
    opacity: 1;
}

  .justificado {
    text-align: justify;
    word-spacing: 2px;
  }

  .contenedor {
    width: 80%; /* Ancho del contenedor */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    padding: 20px; /* Espaciado interno */
    background-color: #f0f0f0; /* Color de fondo del contenedor */
}

.animated-card {
  transform: translateX(0%); /* Empieza fuera del área visible hacia la derecha */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Define la transición de animación */
}

.animated-card.active {
  opacity: 1;
  transform: translateX(100%); 
}

 

  @media screen and (max-width: 768px) {
    input {
      width: 50%;
    }

    .footer {
      text-align: right;
    }

    .terminos {
      font-size: 8px;
    }

    .divOportunidades {
      display: none;
    }

    .fechaPublicacion {
      font-size: 10px;
    }

    .modal-contacto {
      width: auto !important;
    }

    .part1
    .part2
    .part3 {
      width: '100%' !important;
    }

    .detPart4
    .detPart3
    .detPart2
    .detPart1 {
      width: '100%' !important;
    }

    .detallles {
      display: block !important; 
    }

  
    .vacantes {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
   
  }
  
  .card-visible {
      opacity: 1; /* Se vuelve visible */
      transform: translateX(0); /* Se mueve a su posición final */
  }

    
    .leyenda {
      font-size: 9px;
    }

    .vacanteId {
      display: none;
    }

    .detalle {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .btnRegresar {
      display: block;
    }

    .strongFecha {
      display: none;
    }

  

  @media screen and (min-width: 768px) {
  
    .footer {
      text-align: right;
    }

    .detallles {
      display: flex ; 
      flex-direction: row;
      
    }

    .divPostularse {
      display: none;
    }

    .part1 {
      width: 25%;
    }
  
    .part2 {
      width: 25%;
    }
  
    .part3 {
      width: 50%;
    }


    .detPart1 {
      width: 25%;
    }

    .detPart2 {
      width: 25%;
    }
    .detPart3 {
      width: 25%;
    }
    .detPart4 {
      width: 25%;
    }

    .vacantes {
      margin-left: 20;
      margin-right: 20;
    }



  }

  /* width */
/* ::-webkit-scrollbar {
  width: 0px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888; 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

.video-container {
  position: relative;
  height: 100vh;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;   
  z-index: 1;
}

.fullimg{
  height: 100%;
  width:100%;
  z-index: 4;
  color:#051D49;
  background-size: cover;
  
  background-attachment: fixed;
  background-repeat: no-repeat;
}

p, li {
  font-size: 1.0em;
  font-family: 'Red Hat Display',sans-serif;
}

.row {
  padding: 0 !important;
  margin: 0 !important;
}

.text-blanco {color:#ffffff;}

.bg-bolsatrabajo {
  background-image: url("../asset/images/bg-bolsatrabajo.jpg");
  position: relative;
  z-index: 1;
  color:#051D49;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  background-position-y: 50px;
}

.alphabg_degtop {
  background: linear-gradient(-100deg, rgba(234,233,255,0) 0%, rgba(5, 29, 73,0) 30%, rgba(5, 29, 73,0.8) 70%, rgba(5, 29, 73,1) 100%);
}






